import React from 'react';
import { ScrollToTop } from '../components/scroll_to_top/scroll_to_top';
import { SEO } from '../components/seo/seo';
import { LoginForm } from '../components/login/login_form';

const LoginPage = () => {
  return (
    <ScrollToTop>
      <SEO title="Login to Your Omnia Fishing Account" />
      <LoginForm />
    </ScrollToTop>
  );
};
export default LoginPage;
